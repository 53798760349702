export function GetWindowWidth() {
	let w = window;
	let d = document;
	let e = d.documentElement;
	let g = d.getElementsByTagName('body')[0];
	let ww:number = w.innerWidth || e.clientWidth || g.clientWidth;
	// let h = w.innerHeight|| e.clientHeight|| g.clientHeight;
	
	
	return ww;
}
