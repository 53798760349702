/* eslint-disable no-mixed-spaces-and-tabs */
import React from "react";
import SubTitle from "../../Components/Parts/SubTitle";
import Detail from "../../Components/Parts/Detail";
import Url, { Url2 } from '../../Components/Parts/Url';
import PartsGroup from "../../Components/Parts/PartsGroup";
import Title from "../../Components/Parts/Title";
import Command from "../../Components/Parts/Command";

const sampleCodeState2 = "" +
	"// Correct\n" +
	"this.setState((state, props) => ({\n" +
	"  counter: state.counter + props.increment\n" +
	"}));";

const reactMemo = () => (
	<>
		<Title text={"JSXの例（typescriptの比較は未来に載せる予定）"}/>
		<PartsGroup><SubTitle text={"Reactのデザイン"}/>
			<Detail text={"Material-UI"}/>
			<Url text={"https://material-ui.com/ja/getting-started/usage/"}/>
			<Detail text={"Material-UI tableデザイン"}/>
			<Url text={"https://material-ui.com/ja/components/tables/"}/>
			<Detail text={"Bootstrap"}/>
			<Url text={"https://getbootstrap.com/"}/>
			<Detail text={"Material-UIのサイドバー"} />
			<Url text={"https://material-ui.com/components/drawers/"} />
		</PartsGroup>
		<PartsGroup><SubTitle text={"ユーザ定義のコンポーネント"}/>
			<Detail text={"大文字で始めること。（html系の物と混ざらないため）"}/>
		</PartsGroup>
		<PartsGroup><SubTitle text={"stateの注意点"}/>
			<Url text={"https://ja.reactjs.org/docs/state-and-lifecycle.html"}/>
		</PartsGroup>
		<PartsGroup><SubTitle text={"stateの注意点2"}/>
			<Url text={"https://ja.reactjs.org/docs/state-and-lifecycle.html"}/>
			<Detail text={"setStateでstateやpropsを渡してはいけない。 \nする場合には関数を渡す事。\n" + sampleCodeState2}/>
		</PartsGroup>
		<PartsGroup><SubTitle text={"改行に関して"}/>
			<Url text={"https://qiita.com/YIPG/items/0f3edf9217886049eab6"}/>
			<Detail text={"改行を行えるようにするには、styleでの指定が必要。\nその際、コンポーネントに渡すデータを{}でくくる必要がある\n" +
			"改行パターンは公式を参照\n"}/>
			<Url headerText={"公式"} text={"https://developer.mozilla.org/ja/docs/Web/CSS/white-space"}/>
		</PartsGroup>
		<PartsGroup><SubTitle text={"ASP.NET Coreとのaxiosを利用した連携"}/>
			<Url text={"https://docs.microsoft.com/ja-jp/aspnet/core/security/cors?view=aspnetcore-3.1#cpo"}/>
			<Detail text={"AllowCredentials を設定すると安全で無いとの記述があるがこれは併用した場合のため、設定する必要がある。（個別設定もあるのかどうか？）\n" +
			"これを設定して、axios.createで「withCredentials: true」を設定すれば後は、説明に従って、利用する可能性のあるURLのみ設定すればいけそう。"}/>
		</PartsGroup>
		<PartsGroup><SubTitle text={"eslintの初期設定"}/>
			<Url text={"https://docs.microsoft.com/ja-jp/aspnet/core/security/cors?view=aspnetcore-3.1#cpo"}/>
			<Detail text={"yarn add eslint \n" +
			"eslint --init\n"}/>
		</PartsGroup>
		<PartsGroup><SubTitle text={"package.json"}/>
			<Url text={"https://qiita.com/righteous/items/e5448cb2e7e11ab7d477"}/>
			<Detail text={"記号の意味（以下は各バージョンレベルが一致すればバージョンアップする）：\n^ :メジャーバージョンに一致する\n~ :マイナーバージョンに一致する"}/>
		</PartsGroup>
		<PartsGroup><SubTitle text={"リサイズイベント"}/>
			<Url text={"https://qiita.com/eielh/items/f8268b8bfeab887c8405"}/>
			<Detail text={"react-event-listener"}/>
		</PartsGroup>
		<PartsGroup>
			<SubTitle text={"package.json"}/>
			<Url text={"https://qiita.com/righteous/items/e5448cb2e7e11ab7d477"}/>
			<Detail text={"記号の意味（以下は各バージョンレベルが一致すればバージョンアップする）：\n^ :メジャーバージョンに一致する\n~ :マイナーバージョンに一致する"}/>
		</PartsGroup>
		<PartsGroup>
			<SubTitle text={"Material-UI メニューのハンバーガーを右に配置する際に右寄せにする方法"}/>
			<Detail text={"createStylesで作成するstyle情報に以下を追加する"}/>
			<Command text={"grow: {\n" +
			"\tflexGrow: 1,\n" +
			"},"}/>
			<Detail text={"AppBar\n" +
			"->Toolbar\n" +
			"のIconButtonの左に以下を追加する"}/>
			<Command text={"<div className={classes.grow}/>"}/>
		</PartsGroup>
	</>
);


export default reactMemo;
