import React from "react";
import Title from "../../Components/Parts/Title";
import PartsGroup from "../../Components/Parts/PartsGroup";
import SubTitle from "../../Components/Parts/SubTitle";
import Command from "../../Components/Parts/Command";

const yarnMemo = () => (
	< div>
		<Title text={"yarnのコマンド"} />
		<PartsGroup>
			<SubTitle text={"yarn install に同じ"}/>
			<Command text={"yarn"}/>
		</PartsGroup>
		<PartsGroup>
			<SubTitle text={"XXXを追加する。\n大抵は、@types/XXX も同時に行う"}/>
			<Command text={"yarn add XXX"}/>
		</PartsGroup>
	</div>
);

export default yarnMemo;