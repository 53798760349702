import React from "react";
import GetData from "../content/GetData";
import TestButton from "../content/TestButton";
import UserName from "../content/UserName";
import PostData from "../content/PostData";

class MyAwesomeComponent extends React.Component {
    render() {
        return (
            <div>Hello 世界</div>
        );
    }
}

class Data03 extends React.Component{
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
            <h1 style={{color:'blue'}}>Data03です。</h1>
        )
    }
}

//Data03より下の書き方をしてrenderでレンダリングするイメージの方がよさそう？（まとめてレンダリングする場合）
const Data04: React.FC = () => {
    return (
        <div style={{color:'blue'}}>data04です</div>
    );
};

class TopPage extends React.Component{

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div>
                <MyAwesomeComponent />
                <UserName name="適度に適当!!2" />
                <TestButton submit={alert}/>
                <GetData />
                <hr />
                <Data03 />
                <Data04 />
                <PostData />

            </div>
        )

    }
}

export default TopPage;
