import * as React from 'react';
import * as ReactDOM from 'react-dom';

/* JavaScriptの記述方法

import React from 'react';
class UserName extends React.Component {
  render() {
    return (
      <div>
        {this.props.name}さん。こんにちは。
      </div>
    );
  }
}
UserName.propTypes = {
  name: React.PropTypes.string,
};
export default UserName;

*/

type Props = {
    name: string;
}
interface State { }
class UserName extends React.Component<Props, State>{
    render(){
        return <div>{this.props.name}さん。こんにちは。</div>
    }
};

export default UserName;