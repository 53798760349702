import React, {useEffect, useState} from "react";
// import axios from "axios";
import * as TestProps from "./TestProps";

interface Data {
    date: Date;
    temperatureC: number;
    temperatureF: number;
    summary: string;
}

//すごい参考になるサンプル（全部を実装はしていない）
//https://www.robinwieruch.de/react-hooks-fetch-data

const GetData: React.FC = () => {
    const textInput = React.createRef<HTMLInputElement>();
    const [data, setData] = useState<Data[]>([]);
    const [query, setQuery] = useState('redux');
    const [search, setSearch] = useState('');
    const fetchData = async () => {
        try {
            // const result = await axios(
            //     "http://localhost:5000/WeatherForecast",
            // );
            // let ddd: Data[] = result.data as Data[];
            // setData(ddd);
            setConnectionStatus('サーバーからのデータ取得：成功');
        } catch (err) {
            setConnectionStatus('サーバーからのデータ取得：失敗：' + err);
        }
    };
    const [dispData, setDispData] = useState('');
    const [connectionStatus, setConnectionStatus] = useState('未実施');

    //画面表示時に毎回反映（実行）する
    //このサンプルは何度も実行されないようにしている。（最後の ,[] でそれになってるらしい）
    useEffect(() => {
        fetchData();
    }, []);

    //クリック時に反映する
    const click = () => {
        fetchData();
        dispInput();
    };

    //入力値の処理サンプル
    const dispInput = (): void => {
        if (textInput.current && textInput.current.value) {
            setDispData(textInput.current.value);
        } else {
            setDispData('なし');
        }
    };


    //constテスト
    const testSearch: string = "Search"; //const, letは同じような記述で行ける。

    //constのstructテスト
    const testObj: {
        name: string;
        age: number;
    } = {
        name: "名前test",
        age: 20,
    };

    //constのinterfaceテスト
    interface testInterface {
        data1: string;
        data2: number;
    }

    const dataInterfaceTest: testInterface = {
        data1: "データ1",
        data2: 20,
    };

    //ジェネリックテスト
    function testGene<T>(text: T) {
        return text;
    }

    //Propsテスト


    return (
        <div>
            <input
                type="text"
                placeholder="テキスト"
                ref={textInput}
            />
            <button type="button" onClick={click}>
                {testSearch}
            </button>
            {/*<connectServer text={connectionStatus}/>*/}
            <div>入力値：{dispData}</div>


            <table className="testTableHeader">
                <tr>
                    <td className="testTableHeader">summary</td>
                    <td className="testTableHeader">temperatureC</td>
                    <td className="testTableHeader">date</td>
                </tr>
                {data.map(val => (
                    <tr className="testTable">
                        <td className="testTable">{val.summary}</td>
                        <td className="testTable">{val.temperatureC}</td>
                        <td className="testTable">{val.date}</td>
                    </tr>
                ))}
            </table>

            <h3>************* 以下表示テスト ***************</h3>

            <div>表示テスト：{testObj.name}</div>
            <div>表示テスト：{dataInterfaceTest.data2}</div>
            <div>表示テスト：{testGene(testObj.name)}</div>
            {/*Propsテスト*/}
            <TestProps.testP id={"100"}   />
            <TestProps.testP id={"200"} />
            <h3>********** 表示テスト　ここまで **************</h3>

        </div>
    );
};

export default GetData;