import React from "react";

export type testProps = {
    id: string;
}
export const testP: React.FC<testProps> = props => (
    <>
        <h4 id={props.id}>{props.id}</h4>
    </>
);
//上と同じ意味のはず？
export const testP2 = (props: testProps) => {
    return (
        <>
            <h4 id={props.id}>{props.id}</h4>
        </>
    )
};
