import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Home from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Link} from '@material-ui/core';
import YarmMemo from "./Pages/YarmMemo/YarmMemo"
import ReactMemo from "./Pages/ReactMemo/ReactMemo"
import TestHome from "./Pages/TestHome";
import Todo from "./Pages/Todo/Todo";
import IntelliJ from "./Pages/IDE/IntelliJ";
import Blazor from "./Pages/Blazor/Blazor";
import TestConnectApi from "./Pages/TestConnectApi";
import TopPage from "./Pages/TopPage";
import Counter from "./content/Counter";
import Docker from "./Pages/Docker/Docker";
import NotFound from "./content/NotFound";
import {theme} from "./MyTheme.s";
import {routesStyle} from "./Style/RoutesStyle";
import Box from "@material-ui/core/Box";
import ItemsData from "./Pages/Items/Items";

interface ResponsiveDrawerProps {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	container?: Element;
}

export default function ResponsiveDrawer(props: ResponsiveDrawerProps) {
	const {container} = props;
	const _routesStyle = routesStyle();
	const [mobileOpen, setMobileOpen] = React.useState(false);
	
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	
	interface MenuObject {
		linkStr: string;
		text: string;
	}
	
	const menuTopList: MenuObject[] = [
		{linkStr: '/', text: 'Home'},
	];
	
	const menuList: MenuObject[] = [
		{linkStr: '/ReactMemo', text: 'ReactMemo'},
		{linkStr: '/YarnMemo', text: 'YarnMemo'},
		{linkStr: '/Docker', text: 'Docker'},
		{linkStr: '/Todo', text: 'Todo'},
		{linkStr: '/IntelliJ', text: 'IntelliJ'},
		{linkStr: '/Blazor', text: 'Blazor'},
		{linkStr: '/TestConnectApi', text: 'TestConnectAPI'},
		{linkStr: '/Counter', text: 'TestCounter'},
		{linkStr: '/OldTop', text: 'Test01'},
		{linkStr: '/Items', text: '過去メモ'},
	];
	
	const drawer = (
		<>
			<List>
				{menuTopList.map((data) => (
					<ListItem button component={Link} href={data.linkStr} key={data.linkStr} color={"secondary"}
                    className={_routesStyle.linkColor}>
						{/* // リリース時に何故か以下になる。Property 'linkColor' does not exist on type 'Record<never, string>'.  TS2339*/}
						{/* // dense={true} を指定すると、サイズがミニになる。 */}
						{data.linkStr == "/" ? <Home
							className={_routesStyle.linkColor}
						/> : <></>}
						<ListItemText primary={'　' + data.text}/>
					</ListItem>
				))}
			</List>
			<List>
				{menuList.map((data) => (
					<ListItem button component={Link} href={data.linkStr} key={data.linkStr} color={"secondary"}
										className={_routesStyle.linkColor} > {/* // dense={true} を指定すると、サイズがミニになる。 */}
							{/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
						<ListItemText primary={data.text}/>
					</ListItem>
				))}
			</List>
		</>
	);
	
	return (
		<MuiThemeProvider theme={theme}>
			<div className={_routesStyle.root}>
				{/* ↓ これがあるとCSSの共通設定が死ぬので、止める*/}
				{/* <CssBaseline/> */}
				<AppBar position="fixed" className={_routesStyle.appBar} >
					<Toolbar variant={'dense'}>
						<Typography variant="h5"> 雷零霆 </Typography>
						<Box fontSize="h7.fontsize" className={_routesStyle.grow}>（試行中･･･）</Box>
						<IconButton color="inherit" aria-label="open drawer" edge="end" onClick={handleDrawerToggle} className={_routesStyle.menuButton}>
							<MenuIcon/>
						</IconButton>
					</Toolbar>
				</AppBar>
				<nav className={_routesStyle.drawer}>
					{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
					<Hidden smUp implementation="css">
						<Drawer
							container={container}
							variant="temporary"
							// anchor={theme.direction === 'rtl' ? 'right' : 'left'}
							anchor='right'
							open={mobileOpen}
							onClose={handleDrawerToggle}
							classes={{paper: _routesStyle.drawerPaper,}}
							// Better open performance on mobile.
							ModalProps={{keepMounted: true,}}
						>
							{drawer}
						</Drawer>
					</Hidden>
					<Hidden xsDown implementation="css">
						<Drawer classes={{paper: _routesStyle.drawerPaper,}} variant="permanent" open>
							{drawer}
						</Drawer>
					</Hidden>
				</nav>
				<main className={_routesStyle.content}>
					<Typography className={_routesStyle.toolbar}/>
					<Typography>
						<BrowserRouter>
							<Switch>
								<Route exact path='/' component={TestHome}/>
								<Route path='/TestConnectApi' component={TestConnectApi}/>
								<Route path='/ReactMemo' component={ReactMemo}/>
								<Route path='/YarnMemo' component={YarmMemo}/>
								<Route path='/Todo' component={Todo}/>
								<Route path='/IntelliJ' component={IntelliJ}/>
								<Route path='/Blazor' component={Blazor}/>
								<Route path='/Docker' component={Docker}/>
								<Route exact path="/Counter" component={Counter}/>
								<Route exact path="/counter/:myParams" component={Counter}/>
								<Route exact path="/OldTop" component={TopPage}/>
								<Route exact path="/Items" component={ItemsData}/>
								<Route component={NotFound}/>
							</Switch>
						</BrowserRouter>
					</Typography>
				</main>
			</div>
		</MuiThemeProvider>
	);
}
