import React, {useState} from "react";
import Typography from "@material-ui/core/Typography";

// eslint-disable-next-line no-unused-vars
import ItemData from "../../Models/ItemData";
import ListItem from "@material-ui/core/ListItem";

const ItemDetail: React.FC<{data:ItemData}> = props => {
	const[isOpen, setIsOpen  ] = useState(false);
	
	const changeIsOpen = () =>{
		setIsOpen(!isOpen);
	};

	const showDetail = () =>{
		let baseData = props.data.content;
		if(baseData == null || isOpen == false) {
			return <Typography></Typography>
		}
		let datas = baseData.split(/[<br>|\n\r]+/);
		
		return (
			<Typography variant={"body1"}>
				{datas}<br/>
				{datas.map((value, index) => {
					return (<div key={index}>{value}<br/></div>);
				})}
			</Typography>
		)
	};
	
	return (<>
		<ListItem button onClick={changeIsOpen} dense={true}>
			{props.data.title}
		</ListItem>
		{showDetail()}
	</>)
};

export default ItemDetail;
