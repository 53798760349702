import React, {useEffect, useState} from "react";
import axios from "axios";
import HttpCommon, {GetUrlMain} from "../Common/HttpCommon";
import {Button, Theme} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import {createStyles, makeStyles, withStyles} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";

interface Data {
	date: Date;
	temperatureC: number;
	temperatureF: number;
	summary: string;
}

//すごい参考になるサンプル（全部を実装はしていない）
//https://www.robinwieruch.de/react-hooks-fetch-data

const GetData: React.FC = () => {
	const textInput = React.createRef<HTMLInputElement>();
	const [data, setData] = useState<Data[]>([]);
	const [query, setQuery] = useState('redux');
	const [search, setSearch] = useState('');
	const fetchData = async () => {
		try {
			const result = await HttpCommon.get(
				GetUrlMain("/WeatherForecast"),
			);
			let ddd: Data[] = result.data as Data[];
			setData(ddd);
			setConnectionStatus('サーバーからのデータ取得：成功');
		} catch (err) {
			setConnectionStatus('サーバーからのデータ取得：失敗：' + err);
		}
	};
	const [dispData, setDispData] = useState('');
	const [connectionStatus, setConnectionStatus] = useState('未実施');
	
	//画面表示時に毎回反映（実行）する
	//このサンプルは何度も実行されないようにしている。（最後の ,[] でそれになってるらしい）
	useEffect(() => {
		fetchData();
	}, []);
	
	//クリック時に反映する
	const click = () => {
		fetchData();
		dispInput();
	};
	
	//入力値の処理サンプル
	const dispInput = (): void => {
		if (textInput.current && textInput.current.value) {
			setDispData(textInput.current.value);
		} else {
			setDispData('なし');
		}
	};
	
	
	const StyledTableCell = withStyles((theme: Theme) =>
		createStyles({
			head: {
				backgroundColor: theme.palette.common.black,
				color: theme.palette.common.white,
			},
			body: {
				fontSize: 14,
			},
		}),
	)(TableCell);
	
	const StyledTableRow = withStyles((theme: Theme) =>
		createStyles({
			root: {
				'&:nth-of-type(odd)': {
					backgroundColor: theme.palette.background.default,
				},
			},
		}),
	)(TableRow);
	
	const useStyles = makeStyles({
		table: {
			minWidth: 700,
		},
	});
	const classes = useStyles();
	return (
		<div>
			<br/>
			<input
				type="text"
				placeholder="テキスト"
				ref={textInput}
			/>
			<TextField id="outlined-basic" label="検索キーワード（動かない）" variant="outlined" size={"small"} />
			{/*<button type="button" onClick={click}>*/}
			{/*    Search*/}
			{/*</button>*/}
			<br/>
			<Button variant={"contained"} color={"primary"} onClick={click} fullWidth={true}>Search</Button>
			<div>接続状況：{connectionStatus}</div>
			<div>入力値：{dispData}</div>
			
			<TableContainer component={Paper}>
				<Table className={classes.table} size="small" aria-label="customized table">
					<TableHead>
						<TableCell>summary</TableCell>
						<TableCell>temperatureC</TableCell>
						<TableCell>date</TableCell>
					</TableHead>
					<TableBody>
						{data.map(val => (
							<StyledTableRow key={val.summary}>
								<StyledTableCell>{val.summary}</StyledTableCell>
								<StyledTableCell>{val.temperatureC}</StyledTableCell>
								<StyledTableCell>{val.date}</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

export default GetData;