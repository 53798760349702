import React from "react";
import * as PropTypes from "prop-types";

class PartsGroup extends React.Component {
	static propTypes: { children: PropTypes.Requireable<any>; };
	
	render() {
		let {children} = this.props;
		return <>{children}</>
	}
}

PartsGroup.propTypes = {children: PropTypes.any}


export default PartsGroup
