import React from 'react';

class Command extends React.Component<{ text: string }> {
	static defaultProps = {text: ""};
	
	render() {
		let {text} = this.props;
		return (<>
				<div className="command" style={{whiteSpace:"break-spaces"}}>{text}</div>
			</>
		)
	}
}

export default Command;