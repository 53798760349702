import  React, {useState} from 'react';

//POSTサンプル（fetch）
//https://developer.mozilla.org/ja/docs/Web/API/Fetch_API/Using_Fetch

//useSateを利用する場合は以下を行うこと
//行わないとESLintによりエラー扱いとなる。
//npm install eslint-plugin-react-hooks --save-dev

// submitの型指定
type TestButtonProps = {
    submit: (text: string) => any;
}

const TestButton: React.FC<TestButtonProps> = ({ submit }) => {
    const textInput = React.createRef<HTMLInputElement>();

    const handleOnClick = (): void => {
        if (textInput.current && textInput.current.value) {
            let data = fetch('http://localhost:5000/weatherforecast')
                .then(response=>
                {
                    const data = response.json();
                    // setResponce(data);
                    return data;})
                .then(myJson=>{{
                    console.log(JSON.stringify(myJson))}});

            submit(textInput.current.value + data);
            textInput.current.value = '';
        }
    };


    const btnStyle ={
        color: 'red',
    };
    return (
        <div>
            <input
                type="text"
                placeholder="テキスト"
                ref={textInput}
            />
            <button onClick={handleOnClick} style={btnStyle}>Add</button>
        </div>

    )
};

export default TestButton;