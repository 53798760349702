import React from "react";
import GetData from "../TestConnectApi/GetData";
import {Button} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Chip from '@material-ui/core/Chip';

class TestConnectApi extends React.Component {
	
	render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
		return (
			<>
				<Button variant="contained" color="primary" fullWidth={true}>
					Sampleページ
				</Button>
				<Chip variant={"outlined"} size={"small"} label={"secondary!"} color="secondary"/>
				<GetData/>
			</>
		)
		
	}
}

export default TestConnectApi;
