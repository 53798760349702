import React, {useEffect, useState} from "react";
// import axios from "axios";
import {Interface} from "readline";

interface Data {
    date: Date;
    temperatureC: number;
    temperatureF: number;
    summary: string;
}

//すごい参考になるサンプル（全部を実装はしていない）
//https://www.robinwieruch.de/react-hooks-fetch-data

class clsPostData{
    constructor(){
        this.nameData = "testDef";
    }

    nameData: string;
}


const PostData: React.FC = () => {
    const textInput = React.createRef<HTMLInputElement>();
    const postTest = async () => {
        try {
            let data :clsPostData = new clsPostData();
            if (textInput.current && textInput.current.value) {
                data.nameData = textInput.current.value;
            }else{
                data.nameData = "なし";
            }
            // const send = await axios.post(
            //     "http://localhost:5000/api/Values",
            //     data
            // );
            //
            // let ddd: string = send.data;
            // setConnectionStatus('サーバーからのデータ取得：成功' + ddd);
        }catch (err) {
            setConnectionStatus('サーバーからのデータ取得：失敗：' + err);
        }
    };
    const [dispData , setDispData] = useState('');
    const [connectionStatus , setConnectionStatus] = useState('未実施');

    //画面表示時に毎回反映（実行）する
    //このサンプルは何度も実行されないようにしている。（最後の ,[] でそれになってるらしい）
    useEffect(() => {
        // fetchData();
    }, []);

    //クリック時に反映する
    const click = () => {
        postTest();
    };

    //入力値の処理サンプル
    const dispInput = (): void => {
        if (textInput.current && textInput.current.value) {
            setDispData(textInput.current.value);
        }else{
            setDispData('なし');
        }
    };

    return (
        <div>
            <input
                type="text"
                placeholder="POSTテキスト"
                ref={textInput}
            />
            <button type="button" onClick={click}>
                Search
            </button>
            <div>状態：{connectionStatus}</div>

        </div>
    );
};

export default PostData;