import { createMuiTheme } from '@material-ui/core/styles';
import {blue, amber, blueGrey, brown, cyan, deepOrange
	, deepPurple, green, grey, indigo, lightBlue, lightGreen, lime, orange, pink, purple
	,	red, teal, yellow} from "@material-ui/core/colors";

export const theme = createMuiTheme({
	palette: {
		type: 'dark',
		primary: deepPurple,
		secondary: lime,
	},
	status: {
		danger: 'orange',
	},
	overrides: {
		MuiButton: {
			root: {
				// ボタン内アルファベット文字を大文字変換しない
				textTransform: 'none',
			},
		},
	},
});


// export const theme = createMuiTheme({
// 	palette: {
// 		primary: indigo,
// 		secondary: green,
// 	},
// 	status: {
// 		danger: 'orange',
// 	},
// });


// const getTheme = () => {
// 	let overwrites = {
// 		"palette": {
// 			"primary1Color": "#1976d2",
// 			"primary2Color": "#0288d1",
// 			"accent1Color": "#7c4dff",
// 			"accent2Color": "#651fff",
// 			"accent3Color": "#b388ff"
// 		}
// 	};
// 	return getMuiTheme(baseTheme, overwrites);
// }
