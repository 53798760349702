import Axios from "axios";

const HttpCommon = Axios.create({
	xsrfHeaderName: 'X-CSRF-Token',
	withCredentials: true,
	headers:{'Access-Control-Allow-Origin': 'https://*.raireitei.com/'}
});

export default HttpCommon;

// ↑を使うと何故か、CORSエラーになる。 axiosでそのままアクセスすれば何も問題ないのに･･･
// create で「withCredentials: true」を設定すると接続できなくなる･･･なぜ？
// axios が XMLHttpRequestを発行している？
// Cookieが必要かもしれない：参考：https://qiita.com/grainrigi/items/007f1e4df7cb14831994
// サーバー側の設定で「AllowCredentials 」は設定する必要がある。　が「AllowAnyOrigin 」は個別設定すること。という意味が以下に書いてある。
// ：https://docs.microsoft.com/ja-jp/aspnet/core/security/cors?view=aspnetcore-3.1

// const UrlMain = "https://preview.raireitei.com/api/";
// const UrlMain = process.env.NODE_ENV === 'development' ? "https://localhost:44322/" : "https://preview.raireitei.com/api/";
//TODO:ダメな場合、Chromeで試そう。firefoxはlocalhostも制約が厳しい…
const UrlMain = process.env.NODE_ENV === 'development' ? "https://localhost:9443/" : "https://www.raireitei.com/api/";


export function GetUrlMain(address:string) {
	if(address.indexOf("/") == 0){
		address = address.slice(1);
	}
	let baseUrl = UrlMain;
	return baseUrl + address;

}
