import React from "react";
import SubTitle from "../Components/Parts/SubTitle";
import Url from "../Components/Parts/Url";
import Detail from "../Components/Parts/Detail";
import Title from "../Components/Parts/Title";

const TestHome: React.FC = () => {
	return (<>
		
		<Title text={"HOME"}/>
		<SubTitle text={"参考にしてメニューを実装する"}/>
		<Url text={"https://getbootstrap.com/docs/4.1/examples/"}/>
		<Detail text={"S:\\uncompression\\bootstrap-4.1.3\\bootstrap-4.1.3"}/>
		<SubTitle text={"ここも良いかも"}/>
		<Detail text={"https://fortunar.github.io/react-sidemenu/#item2.1.1"}/>
		<SubTitle text={"reactstrapで画面割りをして、制御を組み込む？"}/>
	
	</>);
};

export default TestHome
