/* eslint-disable no-mixed-spaces-and-tabs */
import React from "react";
import SubTitle from "../../Components/Parts/SubTitle";
import Detail from "../../Components/Parts/Detail";
import Url, { Url2 } from '../../Components/Parts/Url';
import PartsGroup from "../../Components/Parts/PartsGroup";
import Title from "../../Components/Parts/Title";
import Command from "../../Components/Parts/Command";

const Docker = () => (
  <>
    <Title text={"Docker関連"}/>
    <PartsGroup>
      <SubTitle text={"Docker一覧表示"}/>
      <Detail text={"docker ps"}/>
    </PartsGroup>
    <PartsGroup>
      <SubTitle text={"Dockerイメージの一覧表示"}/>
      <Detail text={"docker images"}/>
    </PartsGroup>


    <PartsGroup>
      <SubTitle text={"Dockerfileのビルド"}/>
      <Detail text={"docker build -t [イメージ名] [Dockerfileのあるディレクトリ]"}/>
      <Detail text={"例：docker build -t test_image ."}/>
      <Detail text={"※イメージ名はスネークである必要がある"}/>
    </PartsGroup>
    
    <PartsGroup>
      <SubTitle text={"Dockerfileの実行"}/>
      <Detail text={"docker run -it --name [イメージ名]"}/>
    </PartsGroup>
    
    <PartsGroup>
      <SubTitle text={"Dockerfileの削除（イメージの削除）"}/>
      <Detail text={"docker rmi [イメージ名]"}/>
    </PartsGroup>

  </>
);


export default Docker;
