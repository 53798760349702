import React from "react";
import PartsGroup from "../../Components/Parts/PartsGroup";
import SubTitle from "../../Components/Parts/SubTitle";
import Title from "../../Components/Parts/Title";
import Detail from "../../Components/Parts/Detail";
import Url from "../../Components/Parts/Url";

const Blazor = () => (
	< div>
		<Title text={"Blazorメモ"} />
		<PartsGroup>
			<SubTitle text={"小技"}/>
			<Detail text={"HTMLで@(\"sample\")でくくると、記述内容が表示できる"} />
		</PartsGroup>
		<PartsGroup>
			<SubTitle text={"SPAにおける認証"}/>
			<Detail text={"参考サイト"} />
			<Url text={"https://docs.microsoft.com/ja-jp/aspnet/core/security/authentication/identity-api-authorization?view=aspnetcore-3.1"} />
		</PartsGroup>
	</div>
);

export default Blazor;
