import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable'
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
//import logo from "./logo.svg";
import './contentCSS/GetData.css'
import Routes from "./Routes";
import './Components/Parts/_Parts.css'
import './BaseStyle.css'

ReactDOM.render(<Routes />, document.getElementById('root'));



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
