import React from "react";
import PartsGroup from "../../Components/Parts/PartsGroup";
import SubTitle from "../../Components/Parts/SubTitle";
import Title from "../../Components/Parts/Title";
import Detail from "../../Components/Parts/Detail";
import Url from "../../Components/Parts/Url";

const IntelliJ = () => (
	< div>
		<Title text={"IntelliJの設定"} />
		<PartsGroup>
			<SubTitle text={"循環的複雑度"}/>
			<Detail text={"overly complex で設定を検索して、警告を表示するようにチェックする"} />
			<Url text={"https://qiita.com/yut_arrows/items/16749e02313109071338"} />
		</PartsGroup>
	</div>
);

export default IntelliJ;
