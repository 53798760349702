import React from 'react';
import Link from "@material-ui/core/Link";
import {GetWindowWidth} from "../../Common/GetWindowSize";

export class Url extends React.Component<{ text: string, headerText: string }> {
	static defaultProps = {text: "", headerText:""};

	render() {
		let {text} = this.props;
		let {headerText} = this.props;
		let width:number = GetWindowWidth();
		let isMini:boolean = width <= 980;
		let viewTextLength = 30;
		
		if (headerText === ""){
			if(isMini){
				headerText = text;
				if(text.length > viewTextLength){
					headerText = text.substr(0,viewTextLength) + "..."
				}else{
					headerText = text;
				}
			}else{
				headerText = text;
			}
		}
		
		return (<>
				{/* リンクの文字列が長いと、スマホでの表示時にタイトルバーの位置がおかしくなるので仮対処中 */}
				<Link href={text} rel={"noopener"} noWrap={false}>{"Link:" + headerText}</Link>
			</>
		)
	}
}
export default Url;

