import {createStyles, makeStyles} from "@material-ui/core";

const drawerWidth = 240;

export const routesStyle = makeStyles(theme =>
	createStyles({
		root: {
			backgroundColor: '#282c34',
			fontSize: 10,
			[theme.breakpoints.up('sm')]: {
				fontSize: 13,
			},
			flexDirection: 'row',
			display: 'flex',
		},
		drawer: {
			[theme.breakpoints.up('sm')]: {
				width: drawerWidth,
				flexShrink: 0,
			},
		},
		appBar: {
			color:'#def',
			backgroundColor: '#53d',
			[theme.breakpoints.up('sm')]: {
				width: `calc(100% - ${drawerWidth}px)`,
				marginLeft: drawerWidth,
				padding: theme.spacing(0),
				margin: theme.spacing(0),
				minWidth: 24,
			},
			variant:'dense',
		},
		menuButton: {
			[theme.breakpoints.up('sm')]: {
				display: 'none',
			},
			marginRight: theme.spacing(0),
			textAlign: 'right',
		},
		//メニューバーの上の余分な空間をなくす場合は下を有効にする
		toolbar: theme.mixins.toolbar,
		// toolbar:{
		// 	height: 0,
		// },
		useDense:{
			variant:'dense',
		},
		drawerPaper: {
			backgroundColor:'#383c44',
			width: drawerWidth,
		},
		content: {
			flexGrow: 1,
			padding: 0,
			margin:0,
			[theme.breakpoints.up('sm')]: {
				marginLeft:10,
			},
			},
		grow: {
			flexGrow: 2,
		},
		linkColor: {
			color: '#abf',
			paddingTop: 0, //theme.spacing(0)でも良い。関数として処理できるみたい？
			paddingBottom: 0,
			marginTop: 0,
			marginBottom: 0,
			'&:hover': {
				color: '#459',
				backgroundColor: '#cdf',
			},
			style:{
				color: '#abf',
			}
			// backgroundColor: '#fff',
		},
		titleFont:{
			fontFamily:[
				'sans-serif',
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
			// fontWeight:'bold',
		},
	}),
);
