import React from 'react';

class SubTitle extends React.Component<{ text: string }> {
	static defaultProps = {text: ""};
	
	render() {
		let {text} = this.props;
		return (<>
				<h4 className={"sub_title"}>{text}</h4>
			</>
		)
	}
}

export default SubTitle;