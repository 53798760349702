// import useState next to FunctionComponent
import React, {useState} from 'react';

// our components props accept a number for the initial value
const Counter: React.FC<{ initial?: number }> = ({initial = 0}) => {
    // since we pass a number here, clicks is going to be a number.
    // setClicks is a function that accepts either a number or a function returning
    // a number
    const [clicks, setClicks] = useState(initial);
    return (<div>
            <div>Clicks: {clicks}</div>
            <button onClick={() => setClicks(clicks + 1)}>+</button>
            <button onClick={() => setClicks(clicks - 1)}>-</button>
        </div>
    )
};

export default Counter;