import React from "react";
import PartsGroup from "../../Components/Parts/PartsGroup";
import SubTitle from "../../Components/Parts/SubTitle";
import Url from "../../Components/Parts/Url";
import Detail from "../../Components/Parts/Detail";
import Title from "../../Components/Parts/Title";

const Todo = () => (
	< div>
		<Title text={"TODOメモ"} />
		<PartsGroup>
			<SubTitle text={"循環的複雑度（JavaScript）"}/>
			<Url text={"https://qiita.com/taumu/items/b551c643853f42db18c5#comment-4332fbc50ad5fe020f35"}/>
			<Detail text={"JavaScriptの設定？"}/>
		</PartsGroup>
	</div>
);

export default Todo;
