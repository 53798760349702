import React, {useEffect, useState} from "react";
// eslint-disable-next-line no-unused-vars
import {withStyles, Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import HttpCommon, {GetUrlMain} from "../../Common/HttpCommon";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
// eslint-disable-next-line no-unused-vars
import ItemData from "../../Models/ItemData";
import ItemDetail from "./ItemDetail";
import ListItem from "@material-ui/core/ListItem";
import {Link} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";

// すごい参考になるサンプル（全部を実装はしていない）
// https://www.robinwieruch.de/react-hooks-fetch-data

const ItemsData: React.FC = () => {
  const textInput = React.createRef<HTMLInputElement>();
	const [data, setData] = useState<ItemData[]>([]);
	const fetchData = async () => {
		try {
			setConnectionStatus('（取得中...）');
			const result = await HttpCommon.get(
				GetUrlMain("/TestData"),
			);
			let datas = result.data as ItemData[];
			setData(datas);
			// setConnectionStatus('サーバーからのデータ取得：完了');
			setConnectionStatus(''); // 完了時は空にする
		} catch (err) {
			setConnectionStatus('（サーバーからのデータ取得：失敗：' + err + '）');
		}
	};
	const [connectionStatus, setConnectionStatus] = useState('(初期処理中)');
	
	//画面表示時に毎回反映（実行）する
	//このサンプルは何度も実行されないようにしている。（最後の ,[] でそれになってるらしい）
	useEffect(() => {
		fetchData();
	}, []);
	
	//クリック時に反映する
	const click = () => {
		fetchData();
	};
	
	
	const StyledTableCell = withStyles((theme: Theme) =>
		createStyles({
			head: {
				backgroundColor: theme.palette.common.black,
				color: theme.palette.common.white,
			},
			body: {
				fontSize: 14,
			},
		}),
	)(TableCell);
	
	const StyledTableRow = withStyles((theme: Theme) =>
		createStyles({
			root: {
				'&:nth-of-type(odd)': {
					backgroundColor: theme.palette.background.default,
				},
			},
		}),
	)(TableRow);
	
	const useStyles = makeStyles({
		table: {
			minWidth: 700,
		},
	});
	const classes = useStyles();
	
	return (
		<>
			<TableContainer component={Paper}>
				<Table className={classes.table} size="small" aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>過去メモ項目 {connectionStatus}</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map(val => (
							<StyledTableRow key={val.index_ID}>
								<StyledTableCell component="th" scope="row">
									<ItemDetail data={val}/>
								</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default ItemsData;
